import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Logo from "../../assets/images/logo.png";
import AddsModal from "../AddsModal";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { MdKeyboardArrowDown } from "react-icons/md";
import subNavImage from "../../assets/images/subNavimg.png";

import subNavImageTenant from "../../assets/images/navbar/1.png";
import subNavImageLandlord from "../../assets/images/navbar/2.png";
import subNavImageAgent from "../../assets/images/navbar/4.png";
import subNavImageDeveloper from "../../assets/images/navbar/5.png";
import subNavImageAdvertise from "../../assets/images/navbar/6.png";
import subNavImagePM from "../../assets/images/navbar/3.png";
import subNavImagePT from "../../assets/images/navbar/7.png";
import subNavImagePayments from "../../assets/images/navbar/8.png";
import subNavImageCRM from "../../assets/images/navbar/11.png";
import subNavImageApps from "../../assets/images/navbar/12.png";
import subNavImageIntegration from "../../assets/images/navbar/9.png";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [menu, setMenu] = useState(true);
  return (
    <>

      <div
        className="position-sticky top-0 bg-white shadow-lg"
        style={{ zIndex: 999 }}
      >
        <header className="header  ">
          <div className="container">
            <nav class="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand nav-logo" href="/">
                {" "}
                <img src={Logo} alt="Logo" />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse nav-menu overflow-auto " id="navbarNav">
                <ul class="navbar-nav navbar-destop ms-auto align-items-center gap-2  " >
                  {/* <li class="button-light">
                    <a class="" href="/presale">Presale</a>
                    </li> */}
                  {/* <li class="nav-item">
                            <strong>
                                <a className="nav-link" href="tel:+4402034881143">
                                    Sales +44 (0) 203 488 1143
                                </a>
                            </strong>
                        </li> */}
                  {/* <li class="button-light">
                        <a className="" href="/pricing">
                            Pricing
                        </a>
                    </li> */}
                  <div className="hover-nav">
                    <li className="nav-parent">
                      <span>How It Works</span>{" "}
                      <span>
                        <MdKeyboardArrowDown />
                      </span>
                      <ul className="sub-nav">
                        <div className="first">
                          <li>
                            <span>
                              <img
                                src={subNavImageTenant}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/how-it-works-tenants">Tenants</a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImageLandlord}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="how-it-works-landlords">Landlords</a>
                          </li>

                          {/* <li>
                          {" "}
                          <Link>
                            <span></span>
                            <span>fourth</span>
                          </Link>
                        </li> */}
                        </div>
                        <div className="second">
                          <li>
                            <a href="how-it-works-agent">Agents</a>
                          </li>

                          <li>
                            <a href="how-it-works-property-developers">
                              Property Developers
                            </a>
                          </li>
                        </div>
                      </ul>
                    </li>

                    <li className="nav-parent">
                      {/* <span  className="button-light nav-item">
                      <a
                      className="button-light nav-item"
                        href="/services/advertise"
                      >
                        Services & Resources{" "}
                        <MdKeyboardArrowDown />
                      </a>
                    </span> */}
                      <span>Services & Resources</span>{" "}
                      <span>
                        <MdKeyboardArrowDown />
                      </span>
                      <ul className="sub-nav">
                        <div className="first">
                          <li>
                            <span>
                              <img
                                src={subNavImageAdvertise}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/services/advertise">Advertise Property</a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImagePM}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/services/project-management">
                              Manage Property
                            </a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImagePT}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/services/portfolio-tracking">
                              Track Portfolio
                            </a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImagePayments}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/services/payment-gateway">
                              Receive Payments
                            </a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImageCRM}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/services/customer-relationship-management">
                              CRM
                            </a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImageApps}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/services/tools-and-apps">Service Tools</a>
                          </li>

                          <li>
                            <span>
                              <img
                                src={subNavImageIntegration}
                                alt="sub-nav"
                                className="sub-nav-img"
                              />
                            </span>
                            <a href="/integrations">Integrations</a>
                          </li>
                        </div>
                        <div className="second">
                          <li>
                            <a href="/blog">Blog</a>
                          </li>

                          <li>
                            <a href="/faq">FAQs</a>
                          </li>

                          <li>
                            <a href="/contact-us">Partner</a>
                          </li>

                          <li>
                            <a href="/about-us">About Us</a>
                          </li>

                          <li>
                            <a href="/contact-us">Contact Us</a>
                          </li>
                        </div>
                      </ul>
                    </li>
                  </div>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://app.sociallocket.com/login"
                    >
                      <strong>Log in</strong>
                    </a>
                  </li>
               
                  <li
                    className="button-light nav-item"
                    onClick={() => setModal(!modal)}
                  >
                    <span
                      className="nav-link"
                      href="https://app.sociallocket.com/register"
                    >
                      Advertise Property
                    </span>
                  </li>
                  <li className="button-dark nav-item">
                    <a
                      className="nav-link"
                      href="https://app.sociallocket.com/register"
                    >
                      <strong>Agent Sign Up</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </div>

      {modal && <AddsModal modal={modal} setModal={setModal} />}
    </>
  );
};

export default Header;
